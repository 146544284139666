import { collection, getFirestore, limit, query } from "firebase/firestore";
import { useSWRUsers } from "../../hooks/admin/useUsers";

import DataTable from "react-data-table-component";
import { useEffect, useMemo, useState } from "react";

import { useRegisteredUsers } from "./components/useRegisteredUsers";
import { useRegions } from "./components/useRegions";
import { useUnits } from "./components/useUnits";
import { LoadingApotekData } from "./LoadingApotekData";
import { Link, redirect } from "react-router-dom";
import { Button } from "./UnitsView";
import { Cancel, DropdownRegion, DropdownUnit, Modal, Save } from "./UnitView";
import { createUser } from "./lib/db";
import DownloadUsersExcel from "./components/DownloadUsersExcel";
import { useLocations } from "./components/useLocations";
import { useAreas } from "./components/useAreas";

export const CreateForm = ({
    units,
    visible,
    cancelClick,
    user,
}: {
    units: [];
    visible: boolean;
    cancelClick: void;
    user?: {};
}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("+47");
    const [unit, setUnit] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    const storeUser = () => {
        createUser({ unit, firstName, lastName, phoneNumber, id: phoneNumber.replace("+", "") }); /*.then(() => {
            setMessage("user created")
            setFirstName("")
            setLastName("")
            setPhoneNumber("+47")
            setUnit("")
        })*/
    };

    useEffect(() => {
        if (phoneNumber.includes(" ")) {
            setErrorMessage("Phone cannot contain spaces");
        }

        if (phoneNumber.includes(" ")) {
            setErrorMessage("Phone cannot contain spaces");
        }
    }, [phoneNumber]);

    return (
        <Modal visible={visible}>
            <div className={"gap-2 flex flex-col w-full mb-4"}>
                <h1 className={"text-xl mb-4"}>Legg til ny bruker</h1>

                <input
                    value={firstName}
                    placeholder={"Fornavn"}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                    className={"max-w-sm bg-gray-100 p-2 rounded border  border-gray-300 text-gray-900 text-sm"}
                />

                <input
                    value={lastName}
                    placeholder={"Etternavn"}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    className={"max-w-sm bg-gray-100 p-2 rounded border  border-gray-300 text-gray-900 text-sm"}
                />

                <input
                    value={phoneNumber}
                    placeholder={"Telefonnummer"}
                    onChange={(e) => {
                        setPhoneNumber("+" + e.target.value.replace(" ", "").replace(/\D+/g, ""));
                    }}
                    className={"max-w-sm bg-gray-100 p-2 rounded border  border-gray-300 text-gray-900 text-sm"}
                />
            </div>

            <DropdownUnit
                selectedUnit={unit}
                units={units}
                onChange={(e) => {
                    setUnit(e.target.value);
                }}
            />

            {message && <div className={"w-full rounded bg-green-500 text-white p-3 my-2"}>{message}</div>}
            {errorMessage && <div className={"w-full rounded bg-red-500 text-white p-3  my-2"}>{errorMessage}</div>}

            <div className={"ml-auto mt-auto flex flex-row"}>
                <div className={"hover:cursor-pointer "} onClick={cancelClick}>
                    <Save onClick={storeUser} />
                </div>
                <div className={"hover:cursor-pointer "} onClick={cancelClick}>
                    <Cancel onClick={cancelClick} />
                </div>
            </div>
        </Modal>
    );
};

export const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <input
            id="search"
            type="text"
            placeholder="Filtrer"
            aria-label="Search Input"
            value={filterText}
            autoFocus
            className={"w-32 p-2 border-2 rounded-l bg-white text-black h-9 focus:border-0"}
            onChange={onFilter}
        />
        <input
            type={"submit"}
            value={"X"}
            className={"bg-blue-950 text-white rounded-r h-9 px-4 hover:bg-blue-800 hover:cursor-pointer"}
            name={"X"}
            onClick={onClear}
        />
    </>
);

interface UserTableProps {
    users?: unknown[];
    progressPending?: boolean;
    button?: () => void;
    exportButton?: () => Element;
}

const UserTable = (props: UserTableProps) => {
    const columns = [
        {
            name: "Etternavn",
            selector: (row) => row.lastName,
            sortable: true,
            cell: (row) => {
                if (!row.userId) {
                    return row.lastName;
                }
                return <Link to={"/admin/users/" + row.userId}>{row.lastName}</Link>;
            },
        },
        {
            name: "Fornavn",
            selector: (row) => row.firstName,
            sortable: true,
            cell: (row) => {
                if (!row.userId) {
                    return row.lastName;
                }
                return <Link to={"/admin/users/" + row.userId}>{row.firstName}</Link>;
            },
        },
        {
            name: "Enhet / Apotek",
            selector: (row) => row.unit,
            sortable: true,
            cell: (row) => (
                <Link to={"/admin/units/" + row.unitId} className={"underline"}>
                    {row.unit}
                </Link>
            ),
        },
        {
            name: "Overenhet / Region",
            selector: (row) => row.region,
            sortable: true,
            cell: (row) => (
                <Link to={"/admin/regions/" + row.regionId} className={"underline"}>
                    {row.region}
                </Link>
            ),
        },
        {
            name: "Telefonnummer",
            selector: (row) => row.phoneNumber,
        },
        {
            name: "Ansattform",
            selector: (row) => {
                if (row.employmentType) {
                    return row.employmentType || "N/A";
                }
                if (row._row) {
                    return row?._row["Ansattform på hovedarbeidsforhold"] || "N/A";
                }
                return "N/A";
            },
        },
        {
            name: "Er registrert",
            selector: (row) => (row.isRegistered ? "J" : "N"),
        },
        {
            name: "Registreringstidspunkt",
            selector: (row) => row.creationTime,
            sortable: true,
        },
    ];

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <FilterComponent
                    onFilter={(e) => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
                <div className={"flex flex-row gap-4 ml-auto"}>
                    <>
                        {props.exportButton}
                        {props.button()}
                    </>
                </div>
            </>
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <>
            <DataTable
                columns={columns}
                data={props.users.filter((item) => {
                    return (
                        (item.firstName + " " + item.lastName).toLowerCase().includes(filterText.toLowerCase()) ||
                        item.firstName.toLowerCase().includes(filterText.toLowerCase()) ||
                        item.lastName.toLowerCase().includes(filterText.toLowerCase()) ||
                        item.phoneNumber.toLowerCase().includes(filterText.toLowerCase()) ||
                        item.region.toLowerCase().includes(filterText.toLowerCase()) ||
                        item.unit.toLowerCase().includes(filterText.toLowerCase())
                    );
                })}
                persistTableHead
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[25, 50, 100, 500]}
                progressPending={props.progressPending}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                conditionalRowStyles={[
                    {
                        when: (row) => !!row.isRegistered,
                        classNames: ["bg-green-200"],
                        style: {
                            backgroundColor: "rgba(185,237,157,0.63)",
                            color: "black",
                        },
                    },
                ]}
            />
        </>
    );
};

const ExportButton = ({ locations, areas, users }) => {
    return !!locations && !!areas ? (
        <DownloadUsersExcel
            users={users.map((user) => {
                return {
                    Fornavn: user.firstName,
                    Etternavn: user.lastName,
                    Telefonnummer: "+" + user.userId,
                    Enhet: user.unit,
                    Region: user.region,
                    Lokasjon: locations ? locations[user.location] : "",
                    Område: areas ? areas[user.area] : "",
                    Ansattform: (user._row && user._row["Ansattform på hovedarbeidsforhold"]) || "N/A",
                    "Er registrert": !!user.creationTime ? "J" : "N",
                    Registreringstidspunkt: user.creationTime,
                };
            })}
        />
    ) : (
        <div className={"bg-green-500 rounded py-1.5 px-2 text-white ml-auto"}>
            <div role="status">
                <svg
                    aria-hidden="true"
                    className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-gray-950"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill=""
                    />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="white"
                    />
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

const UsersView = () => {
    const db = getFirestore();

    const imagesRef = collection(db, "/users");
    const q = query(imagesRef, limit(3000));

    const { users: dbUsers } = useSWRUsers();

    const { regions, regionsAsList } = useRegions();

    const { locations } = useLocations();

    const { areas } = useAreas();

    const { units, unitsAsList } = useUnits();

    const { authUsers, usersByUserId, usersByPhoneNumber } = useRegisteredUsers();

    const [progressPending, setProgressPending] = useState<boolean>(true);

    useEffect(() => {
        if (regions && authUsers && units) {
            setProgressPending(false);
        }
    }, [regions, authUsers, dbUsers, units]);

    const users = useMemo(() => {
        if (!(dbUsers && authUsers && regions && units)) {
            return [];
        }

        return dbUsers
            ?.map((userDoc) => {
                const regUser = usersByPhoneNumber[userDoc.id];
                const region = regionsAsList.find((region) => region.id === units[userDoc.unit]?.region);
                return {
                    id: regUser?.uid,
                    isRegistered: !!regUser?.uid,
                    phoneNumber: "+" + userDoc.id,
                    userId: userDoc.id,
                    firstName: userDoc.firstName || "N/A",
                    lastName: userDoc.lastName || "N/A",
                    unit: units[userDoc.unit]?.name,
                    unitId: userDoc.unit,
                    region: regions[units[userDoc.unit]?.region] || "N/A",
                    regionId: units[userDoc.unit]?.region,
                    creationTime: regUser ? new Date(Date.parse(regUser?.metadata.creationTime)).toISOString() : "",
                    location: region.location,
                    area: region.area,
                    _row: userDoc._row,
                };
            })
            .sort((a, b) => a.lastName.localeCompare(b.lastName));
    }, [dbUsers, authUsers, regions, units]);

    const [isCreateuserModal, setIsCreateuserModal] = useState(false);

    const CreateUserButton = () => {
        return (
            <Button
                className={"ml-4"}
                label={"Legg til ansatt"}
                onClick={() => {
                    setIsCreateuserModal(true);
                }}
            />
        );
    };

    if (!dbUsers || !regions || !authUsers || !units || !locations || !areas) {
        return (
            <LoadingApotekData
                regions={regions && locations && areas}
                units={units}
                authUsers={authUsers}
                dbUsers={dbUsers && dbUsers.length > 4000}
            />
        );
    }

    return (
        <div>
            <CreateForm
                units={unitsAsList}
                visible={isCreateuserModal}
                cancelClick={() => {
                    setIsCreateuserModal(false);
                }}
            />

            <UserTable
                exportButton={<ExportButton users={users} locations={locations} areas={areas} />}
                button={CreateUserButton}
                progressPending={progressPending}
                users={users}
            />
        </div>
    );
};

export default UsersView;
