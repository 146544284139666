import * as React from "react";
import { lazy } from "react";
import { useEffect, useState } from "react";
import { SuspenseWithPerf, useAuth, useSigninCheck, useUser } from "reactfire";
import { WideButton } from "./display/Button";
import { CardSection } from "./display/Card";
import { LoadingSpinner } from "./display/LoadingSpinner";
import film from "../public/bg_loop_10-sec_v2.webm?url";
import DNAMerke from "./assets/DNA_merke.png?url";
import {
    ConfirmationResult,
    getAuth,
    PhoneAuthProvider,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signInWithCustomToken,
    User,
} from "firebase/auth";
import { createBrowserRouter, Outlet, RouterProvider, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";

const Sporreskjema = lazy(() => import('./display/Sporreskjema'));
const Selfie = lazy(() => import('./display/Selfie'));
const Screenview = lazy(() => import('./display/Screenview'));
const Personvern = lazy(() => import('./display/Personvern'));
const DNAScreen = lazy(() => import('./display/DNA'));
const DNAWebReceiver = lazy(() => import('./display/DNAWebReceiver'));
const ButtonPanel = lazy(() => import('./display/ButtonPanel'));
const Draw = lazy(() => import('./components/Draw'));

import UnitsView from "./display/admin/UnitsView";
import UnitView from "./display/admin/UnitView";
import Menu from "./components/Menu.tsx";
import logo from "./assets/logo_white_text.svg";

const UserView = lazy(() => import("./display/admin/UserView"));
const UsersView = lazy(() => import("./display/admin/UsersView"));
const UserDefaultView = lazy(() => import("./display/admin/UserDefaultView"));
const RegionView = lazy(() => import("./display/admin/RegionView"));
const RegionsView = lazy(() => import("./display/admin/RegionsView"));
const LocationView = lazy(() => import("./display/admin/LocationView"));
const LocationsView = lazy(() => import("./display/admin/LocationsView"));
const AreaView = lazy(() => import("./display/admin/AreaView"));
const AreasView = lazy(() => import("./display/admin/AreasView"));
const StatsDefaultView = lazy(() => import("./display/admin/StatsDefaultView"));
const AdminView = lazy(() => import("./display/admin/AdminView"));
const AdminDefaultView = lazy(() => import("./display/admin/AdminDefaultView"));
const AdminImagesView = lazy(() => import("./display/admin/ImagesView"));
const AdminRealtimeImagesView = lazy(() => import("./display/admin/RealTimeImagesView"));
const AdminDrawView = lazy(() => import("./display/admin/AdminDrawView"));
const AdminDrawsView = lazy(() => import("./display/admin/AdminDrawsView"));

const signOut = (auth) => auth.signOut().then(() => console.log("signed out"));
const signIn = (phoneNumber: string) => {
    //const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const provider = new PhoneAuthProvider(auth);

    //const phoneNumber = "+4747863575";
    const appVerifier = window.recaptchaVerifier;
    console.log("signin");

    return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
            console.log({ confirmationResult });
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            return confirmationResult;
            // ...
        })
        .catch((error) => {
            console.log({ error });
            window.recaptchaVerifier.render().then(function (widgetId) {
                grecaptcha.reset(widgetId);
            });
            throw error;
            // Error; SMS not sent
            // ...
        });
};

const UnauthorizedView = () => {
    return <div>Unauthorized to view this page</div>;
};

export const AuthWrapper = ({
    children,
    fallback,
    needsAdminPriviledge = false,
}: React.PropsWithChildren<{
    fallback: JSX.Element;
    needsAdminPriviledge?: boolean;
}>): JSX.Element => {
    const { data: signInCheckResult } = useSigninCheck({ forceRefresh: false });
    const [sessionTime, setSessionTime] = useLocalStorage("sessionTime", null);
    const auth = useAuth();

    if (signInCheckResult && signInCheckResult.signedIn === true) {
        if (sessionTime && sessionTime + 3600 * 1000 < Date.now()) {
            console.log("session timeout");
            signOut(auth);
        }
    }

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (auth.currentUser) {
            auth.currentUser
                .getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    console.log({ idTokenResult });

                    if (!!idTokenResult.claims.admin) {
                        // Show admin UI.
                        console.log("admin!!!");
                        setIsAdmin(true);
                    } else {
                        // Show regular user UI.
                        setIsAdmin(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setIsAdmin(false);
        }
    }, [auth, signInCheckResult]);

    if (signInCheckResult && signInCheckResult.signedIn === true && needsAdminPriviledge && !isAdmin) {
        return <UnauthorizedView />;
    }

    if (!children) {
        throw new Error("Children must be provided");
    }

    if (signInCheckResult && signInCheckResult.signedIn === true) {
        return children as JSX.Element;
    } else {
        return fallback;
    }
};

export const UserDetails = () => {
    const auth = useAuth();
    const { data: user, status, hasEmitted, error } = useUser();

    console.log({ user, status, hasEmitted, error });

    if (!user) {
        return <span>No user signed in</span>;
    }

    return (
        <>
            <div className="w-screen max-w-[800px] mx-auto flex flex-wrap justify-around p-6">
                <CardSection title="Number">{(user as User).phoneNumber}</CardSection>
                <CardSection title="Number">{(user as User).uid}</CardSection>

                <WideButton id={"signout"} label="Sign Out" onClick={() => signOut(auth)} />
            </div>
        </>
    );
};

const SignInForm = () => {
    const auth = useAuth();
    const [sessionTime, setSessionTime] = useLocalStorage("sessionTime", null);

    const [isLoading, setIsLoading] = useState(false);
    const [isDisplayCodeVerifier, setDisplayCodeVerifier] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isCodeFilled, setIsCodeFilled] = useState(false);
    const [googleConfirmationResult, setGoogleConfirmationResult] = useState<ConfirmationResult>(undefined);
    const [googleCode, setGoogleCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [searchParams] = useSearchParams();

    const c = searchParams.get("service-secret");

    const loginWithToken = async () => {
        const getToken = async (apiKey: string) => {
            return await fetch("https://getcustomtokenforscreens-o47vzlec7q-uc.a.run.app?secret=" + apiKey).then(
                (r) => {
                    return r.json();
                },
            );
        };

        const token = await getToken(c);

        console.log({ token });

        signInWithCustomToken(auth, token.token).then(() => {
            setSessionTime(Date.now);
        });
    };

    const sendCustomOTPRequest = (phoneNumber: string) => {
        return fetch("https://sendotpsms-o47vzlec7q-ew.a.run.app", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ phoneNumber }),
        });
    };

    const exchangeCustomOTPForToken = (phoneNumber: string, otp: string) => {
        return fetch("https://changeotpintoauthtoken-o47vzlec7q-ew.a.run.app", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ phoneNumber, otp }),
        });
    };

    useEffect(() => {
        if (c) {
            console.log("fetching token");
            loginWithToken();
            setInterval(
                () => {
                    loginWithToken();
                },
                15 * 60 * 1000,
            );
        }
    }, [c]);

    useEffect(() => {
        try {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "sign-in-button",
                {
                    size: "invisible",
                    callback: (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        //onSignInSubmit();
                        console.log({ response });
                    },
                },
                auth,
            );
        } catch (e) {
            console.error(e);
        }
    }, []);

    if (c) {
    }

    return (
        <div className="p-6 max-w-[500px] mx-auto">
            <div style={{ zIndex: 1 }} className={"absolute top-0 left-0 w-full pointer-events-none mt-10 px-8"}>
                <div className={"mx-auto w-1/2 max-w-[15vh]"}>
                    <img className={" w-full"} src={DNAMerke} />
                </div>
                <img className="mx-auto mt-12" src={logo} />
            </div>
            <video
                style={{ zIndex: 0, objectFit: "cover" }}
                muted={true}
                autoPlay={true}
                loop={true}
                src={film}
                controls={false}
                draggable={false}
                playsInline={true}
                className={"w-full absolute top-0 left-0 h-full overflow-clip bg-cover object-cover opacity-50"}
            />
            <CardSection title="">
                {!isDisplayCodeVerifier ? (
                    <>
                        <div className={""}>
                            {c}
                            <span>Logg inn med kode fra sms.</span>

                            <div style={{ zIndex: 10000 }} className="relative">
                                <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg
                                        className="w-4 h-4 text-gray-400"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 19 18"
                                    >
                                        <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                                    </svg>
                                    <span className="pl-2 text-xl text-gray-400">+47</span>
                                </div>
                                <input
                                    disabled={isLoading}
                                    type="text"
                                    id="phone-input"
                                    aria-describedby="helper-text-explanation"
                                    autoComplete={"phone-number"}
                                    inputMode="numeric"
                                    className={
                                        "border text-xl rounded-lg block w-full ps-20 p-2.5  bg-gray-700 border-gray-600 placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 pl-20 " +
                                        (isLoading ? "text-gray-400" : "text-gray-100")
                                    }
                                    pattern="[0-9]{8}"
                                    placeholder="81549300"
                                    onChange={(change) => {
                                        if (change.currentTarget?.value.includes("+")) {
                                            setPhoneNumber(change.currentTarget?.value);
                                        }
                                        setPhoneNumber("+47" + change.currentTarget?.value);
                                    }}
                                    required
                                />
                            </div>
                            <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                Skriv inn telefonnummer, åtte siffer
                            </p>
                        </div>
                        <WideButton
                            disabled={isLoading}
                            className="mt-6"
                            id="sign-in-button"
                            label={
                                isLoading ? (
                                    <svg
                                        aria-hidden="true"
                                        className="mx-auto w-8 h-8 animate-spin text-gray-300 fill-blue-600"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                ) : (
                                    "Send kode"
                                )
                            }
                            onClick={() => {
                                setIsLoading(true);
                                sendCustomOTPRequest(phoneNumber)
                                    .then((res) => res.json())
                                    .then((data) => {
                                        console.log(data);
                                        if (data && data.status === "ok") {
                                            setIsLoading(false);
                                            setDisplayCodeVerifier(true);
                                        } else if (data?.msg === "Code already sent and still valid.") {
                                            setErrorMessage("Koden du fikk sist er fortsatt gyldig");
                                            setIsError(true);
                                            setIsLoading(false);
                                            setDisplayCodeVerifier(true);
                                        } else if (data?.msg) {
                                            setErrorMessage(data.msg);
                                            setIsError(true);
                                            setIsLoading(false);
                                        } else {
                                            setErrorMessage("Noe gikk galt. Prøv igjen senere.");
                                            setIsError(true);
                                            setIsLoading(false);
                                        }
                                    })
                                    .catch((err) => {
                                        setIsLoading(false);
                                        setErrorMessage("Noe gikk galt. Sjekk tlfnummer og prøv igjen senere.");
                                        setIsError(true);
                                        console.log(err);
                                    });
                            }}
                        />
                    </>
                ) : (
                    <div className="p-6">
                        <span>Vi har sendt en kode til {phoneNumber}.</span>
                        <form>
                            <input
                                id={"otp"}
                                name={"OTP"}
                                type="text"
                                autoComplete="one-time-code"
                                inputMode="numeric"
                                maxLength={6}
                                pattern="\d{6}"
                                onChange={(change) => {
                                    if (change.currentTarget.value?.length == 6) {
                                        setIsCodeFilled(true);
                                        setGoogleCode(change.currentTarget.value);
                                    } else {
                                        setIsCodeFilled(false);
                                        setGoogleCode("");
                                    }
                                }}
                                style={{ letterSpacing: "0.5em" }}
                                className={
                                    "w-full text-center mb-4 text-4xl rounded bg-gray-200 border-black border-2 text-gray-900 font-mono"
                                }
                            />

                            <WideButton
                                buttonType={"submit"}
                                disabled={isLoading || !isCodeFilled}
                                className="mt-6"
                                id="sign-in-button"
                                label={
                                    isLoading ? (
                                        <svg
                                            aria-hidden="true"
                                            className="mx-auto w-8 h-8 animate-spin text-gray-300 fill-blue-600"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                    ) : isCodeFilled ? (
                                        "Logg inn"
                                    ) : (
                                        <span className={"text-gray-400"}>Logg inn</span>
                                    )
                                }
                                onClick={() => {
                                    setIsLoading(true);
                                    exchangeCustomOTPForToken(phoneNumber, googleCode)
                                        .then((res) => res.json())
                                        .then((res) => {
                                            if (res?.msg) {
                                                console.log(res?.msg);
                                                setErrorMessage(res.msg);
                                                setIsError(true);
                                            } else {
                                                signInWithCustomToken(auth, res.token).then(() => {
                                                    setSessionTime(Date.now);
                                                });
                                            }
                                        })
                                        .catch((err) => {
                                            console.log({ err });
                                        });
                                }}
                            />
                        </form>
                    </div>
                )}
                {isError && <div className="text-red-500 pt-4 text-xl text-center">{errorMessage?.toString()}</div>}
            </CardSection>

            <a className="absolute bottom-0 p-6 mt-24 text-sm" href={"/GDPR_Apotek_1_DNA_v1.pdf"}>
                Personvernserklæring
            </a>
        </div>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        children: [
            {
                path: "/dna-screenview-9",
                element: <Screenview />,
            },
            {
                path: "/cookies",
                element: <Personvern />,
            },
            {
                path: "/",
                element: (
                    <AuthWrapper fallback={<SignInForm />}>
                        <Outlet />
                    </AuthWrapper>
                ),
                children: [
                    {
                        element: (
                            <>
                                <Menu />
                                <Outlet />
                            </>
                        ),
                        path: "/",
                        children: [
                            {
                                path: "/",
                                element: <Sporreskjema />,
                            },
                            {
                                path: "dna",
                                element: <DNAScreen />,
                            },
                        ],
                    },
                    {
                        element: <Sporreskjema />,
                        path: "/register",
                    },
                    {
                        path: "selfie",
                        element: <Selfie />,
                    },
                    {
                        path: "trekning/:drawId",
                        element: <Draw />,
                    },
                ],
            },
            {
                path: "/account",
                element: (
                    <AuthWrapper fallback={<SignInForm />}>
                        <UserDetails />
                    </AuthWrapper>
                ),
            },
            {
                path: "/button-panel/screen/:screenId",
                element: (
                    <AuthWrapper fallback={<SignInForm />}>
                        <ButtonPanel />
                    </AuthWrapper>
                ),
            },
            {
                path: "/display/screen/:screenId",
                element: (
                    <AuthWrapper fallback={<SignInForm />}>
                        <DNAWebReceiver />
                    </AuthWrapper>
                ),
            },
            {
                path: "/admin",
                element: (
                    <AuthWrapper needsAdminPriviledge={true} fallback={<SignInForm />}>
                        <SuspenseWithPerf traceId={"firebase-user-wait"} fallback={<LoadingSpinner />}>
                            <AdminView />
                        </SuspenseWithPerf>
                    </AuthWrapper>
                ),
                children: [
                    {
                        path: "users",
                        element: <UserDefaultView />,
                        children: [
                            {
                                path: ":userId",
                                element: <UserView />,
                            },
                            {
                                path: "*",
                                index: true,
                                element: <UsersView />,
                            },
                        ],
                    },
                    {
                        path: "stats",
                        element: <StatsDefaultView />,
                    },
                    {
                        path: "units",
                        element: <UnitsView />,
                    },
                    {
                        path: "units/:unitId",
                        element: <UnitView />,
                    },
                    {
                        path: "regions",
                        element: <RegionsView />,
                    },
                    {
                        path: "areas",
                        element: <AreasView />,
                    },
                    {
                        path: "areas/:areaId",
                        element: <AreaView />,
                    },
                    {
                        path: "locations",
                        element: <LocationsView />,
                    },
                    {
                        path: "locations/:locationId",
                        element: <LocationView />,
                    },
                    {
                        path: "regions/:regionId",
                        element: <RegionView />,
                    },
                    {
                        path: "draws",
                        element: <AdminDrawsView />,
                    },
                    {
                        path: "draws/:drawId",
                        element: <AdminDrawView />,
                        children: [
                            {
                                path: ":drawId",
                                element: <div>ok</div>,
                            },
                            {
                                path: "*",
                                index: true,
                                element: <div>index</div>,
                            },
                        ],
                    },
                    {
                        path: "draws/:drawId/:stepId",
                        element: <AdminDrawView />,
                        children: [
                            {
                                path: ":drawId",
                                element: <div>ok</div>,
                            },
                            {
                                path: "*",
                                index: true,
                            },
                        ],
                    },
                    {
                        path: "images",
                        element: <AdminImagesView />,
                    },
                    {
                        path: "realtime-images",
                        element: <AdminRealtimeImagesView />,
                    },
                    {
                        index: true,
                        element: <AdminDefaultView />,
                    },
                ],
            },
            {
                element: <h1>404</h1>,
            },
        ],
    },
]);

export const AuthRouter = () => {
    return (
        <SuspenseWithPerf traceId={"firebase-user-wait"} fallback={<LoadingSpinner />}>
            <RouterProvider router={router} />
        </SuspenseWithPerf>
    );
};
