import { useAuth } from "reactfire";
import useSWR from "swr";
import { useMemo } from "react";
import { useSWRUsers } from "../../../hooks/admin/useUsers";
import { useUnits } from "./useUnits";

export const useRegisteredUsers = () => {
    const auth = useAuth();

    const { data: authUsers, isLoading: usersIsLoading } = useSWR(
        "authUsers",
        (key) => {
            return auth.currentUser
                .getIdTokenResult()
                .then((idTokenResult) => {
                    // Confirm the user is an Admin.
                    return fetch("https://getallusers-o47vzlec7q-uc.a.run.app", {
                        headers: new Headers({ Authorization: "Bearer " + idTokenResult.token }),
                    });
                })
                .then((r) => r.json());
        },
        {
            revalidateOnFocus: false,
            revalidateOnBlur: false,
        },
    );

    console.log({ authUsers });

    const usersByUserId = useMemo(() => {
        //return authUsers?.users.map(user => user.uid)
        if (!authUsers) return {};
        return Object.fromEntries(authUsers?.users.map((user) => [user.uid, user]));
    }, [authUsers]);

    const usersByPhoneNumber = useMemo(() => {
        //return authUsers?.users.map(user => user.uid)
        if (!authUsers) return {};
        return Object.fromEntries(authUsers?.users.map((user) => [user.phoneNumber?.replace("+", ""), user]));
    }, [authUsers]);
    return { authUsers, usersByUserId, usersByPhoneNumber };
};

export const useUsersPerUnit = () => {
    const { usersByPhoneNumber } = useRegisteredUsers();

    const { unitsAsList: units } = useUnits();
    const usersPerUnit = {};
    units?.forEach((unit) => {
        usersPerUnit[unit.id] = { users: [] };
    });

    const { users } = useSWRUsers();
    users?.forEach((user) => {
        if (user) {
            usersPerUnit[user.unit]?.users.push({
                userId: user.id,
                uid: usersByPhoneNumber && usersByPhoneNumber[user.id]?.uid,
                registeredUser: usersByPhoneNumber[user.id],
                ...user,
            });
        }
    });

    return usersPerUnit;
};
