import { useCollection } from "@tatsuokaniwa/swr-firestore";

export const useUnits = () => {
    const { data: units, ...rest } = useCollection<{ name: string; region: string; area: string; location: string }>(
        {
            path: "units",
        },
        { revalidateOnFocus: false, dedupingInterval: 10000 },
    );

    return {
        unitsAsList: units,
        units: units?.reduce(function (map, obj) {
            map[obj.id] = { name: obj.name, region: obj.region, area: obj.area };
            return map;
        }, {}),
        ...rest,
    };
};

export const useUnitsByRegion = (regionId: string) => {
    const { unitsAsList } = useUnits();
    return unitsAsList?.filter((unit) => unit.region === regionId) || [];
};
