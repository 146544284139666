import { useState } from "react";
import DNAMerke from "../assets/DNA_merke.png?url";
import menuBurger from "../assets/menuBurger.svg?url";
import menuX from "../assets/menuX.svg?url";
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useFirebaseApp } from "reactfire";

const Menu = () => {
    const navigate = useNavigate();
    const firebaseApp = useFirebaseApp();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const auth = getAuth(firebaseApp);

    const Logout = () => <div
        onClick={() => {
            auth.signOut();
            navigate("/");
            setIsMenuOpen(false);
        }}

        className={"rounded-full bg-white text-brand-midGreen absolute  bottom-20 w-auto px-10 py-2 mt-10 "}>Logg ut</div>
    return (
        <div style={{zIndex: 100000}} className={"overflow-clip absolute top-0 left-0 h-[100dvh] w-screen pointer-events-none"}>
            <div
                //onClick={() => setIsMenuOpen(!isMenuOpen)}
                style={{
                    zIndex: 1000001,
                    transform: isMenuOpen ? "" : `translate3d(100vw,0,0)`,
                    transition: "transform 0.7s cubic-bezier(0.22, 1, 0.36, 1)",
                }}
                className="bg-brand-darkGreen pointer-events-auto w-screen h-[100dvh] absolute top-0 left-0 flex flex-col items-center justify-center gap-6 text-xl"
            >
                <img className={"absolute top-8 right-6 p-6"} onClick={() => setIsMenuOpen(!isMenuOpen)} src={menuX} />

                <Link onClick={() => {setIsMenuOpen(false)}} to={"/"}>Hjem</Link>
                <Link onClick={() => {setIsMenuOpen(false)}} to={"/selfie"}>Ta ny selfie</Link>
                <Link onClick={() => {setIsMenuOpen(false)}} to={"/dna"}>Se DNA-et</Link>

                <Logout />
            </div>
            <menu className={"absolute top-0 left-0 w-full pointer-events-none grid grid-cols-2 mt-10 px-8"}>
                <div className={"mr-auto w-1/2"}>
                    <img className={"max-w-[15vh] w-full"} src={DNAMerke} />
                </div>
                <div className={"ml-auto pointer-events-auto"}>
                    <img className={""} onClick={() => setIsMenuOpen(!isMenuOpen)} src={menuBurger} />
                </div>
            </menu>
        </div>
    );
};

export default Menu;
